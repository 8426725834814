import React from "react"
import styled from 'styled-components'
import "../components/layout.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import servico1 from '../images/servico1.jpg'
import servico2 from '../images/servico2.jpg'
import servico3 from '../images/servico3.jpg'



const Servicos = ({lightBg, imgStart, lightText, lightTextDesc, start, img, alt}) => {

  return (
  <>
  <Layout>
    <SEO title="Servicos" />
    <InfoSec lightBg={lightBg=true}>
    <InfoRow imgStart={imgStart=false} >
<InfoColumn>
  <TextWrapper>
<Heading lightText={lightText=false}>Working Gymnastics</Heading>
<Subtitle lightTextDesc={lightTextDesc=false}> Working Gymnastics is a type of gymnastics performed in the workplace with short exercises, approximately 20 minutes, with the objective of improving the health and quality of life of employees.<br/>The exercises, which use breathing, stretching, and postural correction techniques, avoid occupational diseases and the appearance of pain and injuries.</Subtitle>
  </TextWrapper>
</InfoColumn>
<InfoColumn>
  <ImgWrapper start={'start'}>
  <Img src={servico1} alt={alt}/>
  </ImgWrapper>
</InfoColumn>
    </InfoRow>
    </InfoSec>
    <InfoSec lightBg={lightBg=false}>
    <InfoRow imgStart={imgStart=true}>
<InfoColumn>
  <TextWrapper>
<Heading lightText={lightText=true}>Pilates / Yoga classes</Heading>
<Subtitle lightTextDesc={lightTextDesc=true}>These classes can be private or in small groups and can be taken in an online or in-person format. Classes are structured to help increase motor skills through greater body control and the development of skills such as strength, flexibility, and a sense of balance.<br/>All of these gains are sustained by greater body awareness, increased proprioception, and greater efficiency in the management of adversity.</Subtitle>
  </TextWrapper>
</InfoColumn><InfoColumn>
  <ImgWrapper start={'start'}>
  <Img src={servico2} alt={alt}/>
  </ImgWrapper>
</InfoColumn>
    </InfoRow>
    </InfoSec>
    <InfoSec lightBg={lightBg=true}>
    <InfoRow imgStart={imgStart=false}>
<InfoColumn>
  <TextWrapper>
<Heading lightText={lightText=false}>Positive Psychology</Heading>
<Subtitle lightTextDesc={lightTextDesc=false}>The oriented use of psychophysiological strategies to enhance characteristics necessary to work in the professional context.<br/>Self-efficacy, stress management, increased prolonged attention, planning and organizing tasks, leadership skills, among many others.</Subtitle>
  </TextWrapper>
</InfoColumn>
<InfoColumn>
  <ImgWrapper start={'start'}>
  <Img src={servico3} alt={alt}/>
  </ImgWrapper>
</InfoColumn>
    </InfoRow>    
    </InfoSec>  
  </Layout>
  </>
  )
}

export default Servicos


const InfoSec = styled.div`
color: #fff;
padding: 100px 0;
background: ${({lightBg}) => (lightBg ? '#e7effc ' : '#4f79b8')};
`;

const InfoRow = styled.div`
display: flex;
margin: 0 -15px -75px -15px;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

const InfoColumn = styled.div`
margin-bottom: 0px;
padding-right: 75px;
padding-left: 75px;
flex: 1;
max-width: 50%;
flex-basis: 50%;

@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
`;

const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 40px;

@media screen and (max-width: 768px) {
  padding-bottom: 35px;
}
`;

const Heading = styled.div`
margin-bottom: 24px;
font-size: 40px;
line-height: 1.1;
color: ${({lightText}) => (lightText ? '#f7f8fa' : '#1e3966')};
`;

const Subtitle = styled.p`
max-width: 480px;
margin-bottom: 35px;
font-size: 18px;
line-height: 1.5;
color: ${({lightTextDesc}) => (lightTextDesc ? '#bfccdb' : '#1e3966')};
`;

const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};

`

const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;

`;
